import classNames from "classnames/bind";
import styles from "./Companies.module.scss";

import prologis from "~/assets/images/prologis.png";
import tower from "~/assets/images/tower.png";
import equinix from "~/assets/images/equinix.png";
import realty from "~/assets/images/realty.png";

import React from "react";
const cx = classNames.bind(styles);

const Companies = () => {
  return (
    <section className={cx("wrapper")}>
      <div className={cx( "container", "paddings", "innerWidth", "flexCenter")}>
        <img src={prologis} alt="prologis" />
        <img src={tower} alt="tower" />
        <img src={equinix} alt="equinix" />
        <img src={realty} alt="realty" />
      </div>
    </section>
  );
};

export default Companies;
