import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      // state.user = action.payload;
    },
    getProfileRequest: (state, action) => {
      state.user = null;
    },
    getProfileSuccess: (state, action) => {
      state.user = action.payload;
    }
  },
});

export default userSlice;

