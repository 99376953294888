import { useSwiper } from "swiper/react";

import classNames from "classnames/bind";
import styles from "./Residencies.module.scss";

const cx = classNames.bind(styles);
function SliderButtons() {
  const swiper = useSwiper();

  return (
    <div className={cx("flexCenter","r-buttons")}>
      <button onClick={() => swiper.slidePrev()}>&lt;</button>
      <button onClick={() => swiper.slideNext()}>&gt;</button>
    </div>
  );
}

export default SliderButtons;
