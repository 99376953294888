import { configureStore } from '@reduxjs/toolkit';
import utilsSlice from './slices/UtilsSlice';
import userSlice from './slices/UserSlice';

const initStore = configureStore({
  reducer: {
    utils: utilsSlice.reducer,
    user: userSlice.reducer,
  },
});
export default initStore;
