
export const limit = 10;
// export const checkValidateEmail = text => {
//   let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//   if (reg.test(text) === false) {
//     return false;
//   } else {
//     return true;
//   }
// };

export const checkValidatePhone = text => {
  let reg = /^[0-9]{8,12}$/;
  if (reg.test(text.replace(/\s/g, '')) === false) {
    return false;
  } else {
    return true;
  }
};

export const checkNumber = text => {
  if (isNaN(text)) {
    return false;
  } else {
    return true;
  }
};

export const checkValidatePassword = text => {
  let reg = /^.{6,}$/;
  if (reg.test(text.replace(/\s/g, '')) === false) {
    return false;
  } else {
    return true;
  }
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const errorList = {
  // from api
  INVALID_LOGIN: 'Tài khoản, mật khẩu không đúng hoặc không tồn tại.',
  USER_NOT_FOUND: 'Tài khoản không tồn tại',
  OTP_NOT_MATCH: 'Mã OTP không khớp',
  CURRENT_PASSWORD_MISMATCH: 'Mật khẩu cũ không đúng',
  CAN_NOT_REGISTER_TRAINING_SCHEDULE_TOOK_PLACE: 'Không thể đăng ký lịch đào tạo đã diễn ra',
  MEMBERSHIP_HAS_BEEN_LOCKED: 'Tài khoản của bạn đã bị khóa',
  CAN_NOT_REGISTER_LIVESTREAM_TOOK_PLACE: 'Không thể đăng ký live stream đã diễn ra',
  OTHER_DEVIDE_SINGIN: 'Tài khoản của bạn đang được đăng nhập ở một thiết bị khác, vui lòng đăng nhập lại.',
  PHONE_NUMBER_NOT_FOUND: 'Tài khoản chưa cập nhập số điện thoại',
  // custom message
  PAYMENT_FAILURE: 'Thanh toán thất bại',
  PASSWORD_NOT_MATCH: 'Mật khẩu mới không khớp',
  INPUT_FULL: 'Vui lòng nhập đầy đủ thông tin',
  SUBSCRIBE_SUCCESS: 'Đăng ký thành công',
  DELETE_SUCCESS: 'Huỷ thành công',
  UPDATE_SUCCESS: 'Cập nhật thành công',
  PHONE_VALID: 'Số điện thoại không đúng định dạng 8-12 số',
  ID_NOT_NULL: 'Vui lòng nhập id',
  EMAIL_VALID: 'Email không đúng định dạng',
  PASSWORD_VALID: 'Password không đúng định dạng',
  SELECT_PACKAGE: 'Cần chọn gói thành viên',
  SELECT_PAYMENT: 'Cần chọn phương thức thanh toán',
  NO_CHANGE: 'Không có dữ liệu bị thay đổi',
};
export const getErrorString = errorCode => {
  console.log('getErrorString ----->', errorCode);
  return (
    errorList[errorCode] ||
    `Có lỗi xảy ra vui lòng thử lại sau ${errorCode !== undefined && errorCode ? `(${errorCode})` : ''}`
  );
};
