import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { MdOutlineArrowDropDown } from 'react-icons/md';

import data from '~/utils/accordion';

import classNames from 'classnames/bind';
import styles from './Value.module.scss';

const cx = classNames.bind(styles);
const Value = () => {
  return (
    <section className="wrapper">
      <div className={cx('paddings', 'innerWidth', 'flexCenter', 'container')}>
        <div className="left">
          <div className={cx('image-container')}>
            <img src="https://cdn.pixabay.com/photo/2019/10/18/09/29/cat-4558651__340.jpg" alt="meo" />
          </div>
        </div>

        <div className={cx('flexColStart', 'right')}>
          <span className="orangeText">Our Value</span>
          <span className="primaryText">Value We Give to You</span>
          <span className="secondaryText">
            We always ready to help by providijng the best services for you. <br />
            We beleive a good place to live can make your life better
          </span>

          <Accordion className="accordion" allowMultipleExpanded={false} preExpanded={[0]}>
            {data.map((item, index) => {
            //   const [className, setClassName] = useState(null);
              return (
                <AccordionItem className={`accordionItem`} key={index} uuid={index}>
                  <AccordionItemHeading>
                    <AccordionItemButton className={cx('accordionButton')}>
                      {/* <AccordionItemState>
                        {({ expanded }) => {
                          expanded ? setClassName('expanded') : setClassName('collapsed');
                        }}
                      </AccordionItemState> */}

                      <div className={cx('flexCenter', 'icon')}>{item.icon}</div>
                      <span className="primaryText">{item.heading}</span>
                      <div className={cx('flexCenter', 'icon')}>
                        <MdOutlineArrowDropDown size={20} />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>

                  <AccordionItemPanel>
                    <p className="secondaryText">{item.detail}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Value;
