import classNames from 'classnames/bind';
import styles from './HeaderDefault.module.scss';
import logo from '~/assets/images/logo.png';
import { BiMenuAltRight } from 'react-icons/bi';
import OutsideClickHandler from 'react-outside-click-handler';
import React, { useState } from 'react';
const cx = classNames.bind(styles);

const HeaderDefault = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const getMenuStyles = mnOpened => {
    if (document.documentElement.clientWidth <= 800) {
      return {
        right: !mnOpened && '-100%',
      };
    }
  };

  return (
    <section className={cx('h-wrapper')}>
      <div className={cx('h-container', 'innerWidth', 'paddings')}>
        <img src={logo} alt="logo" width={100} />

        <OutsideClickHandler onOutsideClick={() => setMenuOpened(false)}>
          <div className={cx('flexCenter', 'h-menu')} style={getMenuStyles(menuOpened)}>
            <a href="Residencies">Residencies</a>
            <a href="Our value">Our value</a>
            <a href="Contact us">Contact us</a>
            <a href="Get started">Get started</a>
            <button className="button">
              <a href="Contact">Contact</a>
            </button>
          </div>
        </OutsideClickHandler>
        <div className={cx('menu-icon')} onClick={() => setMenuOpened(pre => !pre)}>
          <BiMenuAltRight size={30} />
        </div>
      </div>
    </section>
  );
};

export default HeaderDefault;
