import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import classNames from 'classnames/bind';
import styles from './Residencies.module.scss';
import data from '~/assets/data/slider.json';
import { sliderSettings } from '~/utils/common';
import SliderButtons from './SliderButtons';

const cx = classNames.bind(styles);

const Residencies = () => {
  return (
    <section className="wrapper">
      <div className={cx('paddings', 'innerWidth', 'container')}>
        <div className={cx('head', 'flexColStart')}>
          <span className="orangeText">Best Choices</span>
          <span className="primaryText">Popular Residencies</span>
        </div>

        <Swiper {...sliderSettings} modules={[Navigation, Pagination, Autoplay]}>
          <SliderButtons />

          {data.map((card, i) => (
            <SwiperSlide key={i}>
              <div className={cx('flexColStart', 'card')}>
                <img src={card.image} alt="home" />

                <span className={cx('secondaryText', 'price')}>
                  <span style={{ color: 'orange' }}>$</span>
                  <span>{card.price}</span>
                </span>

                <span className={cx('primaryText')}>{card.name}</span>
                <span className={cx('secondaryText')}>{card.detail}</span>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-pagination"></div>
      </div>
    </section>
  );
};

export default Residencies;
