import { createSlice } from '@reduxjs/toolkit';
import { getErrorString } from '~/helpers/FunctionUtils';

const utilsSlice = createSlice({
  name: 'utils',
  initialState: {
    message: null,
    isSuccess: false,
    isError: false,
    isLoading: false,
    isLogin: false,
  },
  reducers: {
    requestSuccess: (state, action) => {
      //mutation nhu immutation vi toolkit su dung IMMER
      state.isSuccess = true;
      state.message = getErrorString(action.payload);
    },
    clearSuccess: state => {
      state.isSuccess = false;
      state.message = null;
    },
    requestError: (state, action) => {
      state.isError = true;
      state.message = getErrorString(action.payload);
    },
    clearError: state => {
      state.isError = false;
      state.message = null;
    },
    requestLogin: (state, action) => {
      state.isLogin = true;
      state.message = getErrorString(action.payload);
    },
    clearLogin: state => {
      state.isLogin = false;
      state.message = null;
    },
    handleLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loginAgain: (state, action) => {
      state.isLoginAgain = action.payload;
    },
    changeTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
});

export default utilsSlice;
