import Residencies from "~/components/residencies/Residencies";
import pageRoutes from "~/config/PageRoutes";
import HomePage from "~/pages/home/HomePage";

const publicRoutes = [
  {
    path: pageRoutes.home,
    component: HomePage,
  },
  {
    path: pageRoutes.live,
    component: Residencies,
    layout: null,
  },
  // {
  //   path: '/@:nickname',
  //   component: ProfilePage,
  //   layout: null,
  // },
];

const privateRoutes = [];

export { publicRoutes, privateRoutes };
