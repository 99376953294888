import classNames from "classnames/bind";
import styles from "./Hero.module.scss";
import heroImg from "~/assets/images/hero-image.png";

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { animate, motion } from "framer-motion";

const cx = classNames.bind(styles);

const Hero = () => {
  return (
    <section className={cx("wrapper")}>
      <div className={cx("paddings", "flexCenter", "container", "innerWidth")}>
        <div className={cx("flexColStart", "left")}>
          <div className={cx("title")}>
            <div className={cx("orange-circle")} />
            <motion.h1
              initial={{ y: "2rem", opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                duration: 2,
                type: "spring"
              }}
            >
              Discover <br /> Most Suitable <br /> Property
            </motion.h1>
          </div>

          <div className={cx("flexColStart", "desc")}>
            <span className="secondaryText">
              Find a variety of properties that suit you very easilty
            </span>
            <span className="secondaryText">
              Forget all difficulties in finding a residence for you.
            </span>
          </div>

          <div className={cx("flexCenter", "search-bar")}>
            <FontAwesomeIcon
              className={cx("icon-location")}
              icon={faLocationDot}
            />
            <input type="text" />
            <button className="button">Search</button>
          </div>

          <div className={cx("flexCenter", "starts")}>
            <div className={cx("flexColCenter", "start")}>
              <span>
                <CountUp start={8800} end={9000} duration={4} />
                <span>+</span>
              </span>
              <span className="secondaryText">Premium Products</span>
            </div>

            <div className={cx("flexColCenter", "start")}>
              <span>
                <CountUp start={1950} end={2000} duration={4} />
                <span>+</span>
              </span>
              <span className="secondaryText">Happy Customers</span>
            </div>

            <div className={cx("flexColCenter", "start")}>
              <span>
                <CountUp end={28} />
                <span>+</span>
              </span>
              <span className="secondaryText">Award Winning</span>
            </div>
          </div>
        </div>
        <div className={cx("flexCenter", "right")}>
          <motion.div
            initial={{ x: "7rem", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{
              duration: 2,
              type: "spring"
            }}
            className={cx("image-container")}
          >
            <img src={heroImg} alt="heroImg" />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
