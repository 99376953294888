import classNames from "classnames/bind";
import styles from "./DefaultLayout.module.scss";
import HeaderDefault from "~/components/headers/HeaderDefault";

const cx = classNames.bind(styles);

const DefaultLayout = ({ children }) => {
  return (
    <div className={cx("wrapper")}>
      <div>
        <div className={cx("white-gradient")} />
        <HeaderDefault  />
        <div className={cx("container")}>
          {/* <Sidebar /> */}
          <div className={cx("content")}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
