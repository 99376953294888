import React from "react";
import Companies from "~/components/companies/Companies";
import Hero from "~/components/heros/Hero";

// import classNames from "classnames/bind";
// import styles from "./HomePage.module.scss";
import Residencies from "~/components/residencies/Residencies";
import Value from "~/components/Value/Value";

// const cx = classNames.bind(styles);

const HomePage = () => {
  return (
    <div>
      <Hero />
      <Companies />
      <Residencies />
      <Value />
    </div>
  );
};

export default HomePage;
